import styled from '@emotion/styled';
import Img from 'gatsby-image';
import { breakpoints } from '../../../utils/styles';

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  @media (max-width: ${breakpoints.l}px){
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const ImageContainer = styled.div`
  max-height: 600px;
  overflow: hidden;
  position: relative;

  @media (max-width: ${breakpoints.l}px){
    order: 1;
  }
`;

export const Image = styled(Img)`
  position: absolute;
  height: 100%;
`;

export const TextSection = styled.div`
  display: flex;
  color: ${({ theme }) => theme.textDark};
  background: ${({ theme }) => theme.light};
  justify-content: ${({ opposite }) => opposite ? 'flex-start' : 'flex-end'};

  @media (max-width: ${breakpoints.l}px){
    order: 2;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 32px;
  max-width: 600px;
  height: 500px;

  @media (max-width: ${breakpoints.l}px){
    max-width: 100%;
    padding: 32px 24px;
  }

  @media (min-width: ${breakpoints.xl}px){
    max-width: 800px;
    padding: 32px 64px;
  }
`;

export const Text = styled.p`
  margin: 0;
`;
