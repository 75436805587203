import styled from '@emotion/styled';
import Img from 'gatsby-image';
import { Link } from 'gatsby';

import { breakpoints, dimensions } from '../../../utils/styles';

export const Section = styled.div`
  background: ${({ theme, bgColor }) => bgColor ? theme.light : theme.white};
  padding: 64px 24px;

  @media (max-width: ${breakpoints.l}px){
    padding: 32px 24px;
  }
`;

export const Container = styled.div`
  color: ${({ theme }) => theme.textDark};
  max-width: ${dimensions.contentWidth};
  margin: 0 auto;
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 32px;

  @media (max-width: ${breakpoints.l}px){
    gap: 24px;
  }

  @media (max-width: ${breakpoints.s}px){
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const Item = styled(Link)`
  display: flex;
  flex-direction: column;
  text-decoration: none;
  color: inherit;
`;

export const ImageContainer = styled.div`
  overflow: hidden;
`;

export const Image = styled(Img)`
  height: 360px;
  transition: transform 0.45s ease-in-out;

  @media (max-width: ${breakpoints.l}px){
    height: 300px;
  }
  
  @media (max-width: ${breakpoints.m}px){
    height: 232px;
  }

  @media (max-width: ${breakpoints.s}px){
    height: 260px;
  }

  :hover {
    transform: scale(1.1);
  }
`;

export const Title = styled.h3`
  color: ${({ theme }) => theme.textDark};
  margin-top: 16px;
`;

export const SectionTitle = styled.h2`
  text-align: center;

  @media (max-width: ${breakpoints.s}px){
    text-align: left;
  }
`;

export const ButtonContainer = styled.div`
  text-align: center;
  margin-top: 24px;

  @media (max-width: ${breakpoints.s}px){
    text-align: left;
  }
`;