import React from 'react';
import { RichText } from 'prismic-reactjs';
import { 
  Container, 
  Image, 
  ImageContainer, 
  TextSection, 
  TextContainer, 
  Text 
} from './styles';

const TextImage = ({ title, text, image, opposite }) => {

  // Text
  const textSection = (
    <TextSection opposite={opposite}>
       <TextContainer data-sal='slide-up' data-sal-easing='ease' data-sal-duration='600'>
        <h2>
          {RichText.asText(title.raw)}
        </h2>
        <Text>
          {RichText.asText(text.raw)}
        </Text>
      </TextContainer>
    </TextSection>
  );

  // Image
  const imageSection = (
    <ImageContainer>
      <Image fluid={image && image.fluid} />
    </ImageContainer>
  );

  return (
    <Container>
      {opposite ? imageSection : textSection}
      {opposite ? textSection : imageSection}
    </Container>
  );
};

export default TextImage;