import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Subheader from '../components/page/SubHeader';
import TextImage from '../components/page/TextImage';
import WorkGrid from '../components/work/WorkGrid';

const Work = ({ data }) => {
  // Props
  const projects = data.prismicProjects.data;
  const work = data.allPrismicWork;

  return (
    <Layout>
      <SEO 
        title={projects.meta_title}
        description={projects.meta_description}
        type='article'
      />
      <Subheader 
        title={projects.title}
        text={projects.description}
      />
      <WorkGrid work={work.nodes} />
      {projects.sectors.map((sector, i) => (
        <TextImage
          key={`sector-${i}`}
          title={sector.sector_title}
          text={sector.sector_text}
          image={sector.sector_image}
          opposite={(i + 1) % 2 === 0}
        />
      ))}
    </Layout>
  );
};

export default Work;

export const query = graphql`
  query {
    prismicProjects {
      data {
        meta_description
        meta_title
        description {
          raw
        }
        title {
          raw
        }
        work_title {
          raw
        }
        sectors {
          sector_image {
            alt
            fluid(maxWidth: 1920) {
              ...GatsbyPrismicImageFluid
            }
          }
          sector_text {
            raw
          }
          sector_title {
            raw
          }
        }
      }
    }
    allPrismicWork(sort: {fields: data___date, order: DESC}) {
      nodes {
        data {
          title {
            raw
          }
          featured_image {
            alt
            fluid(maxWidth: 800) {
              ...GatsbyPrismicImageFluid
            }
          }
        }
        uid
        type
        lang
      }
    }
  }
`;