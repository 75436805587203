import React from 'react';
import { RichText } from 'prismic-reactjs';

import {
  Grid, 
  Item, 
  Title,
  Section,
  SectionTitle,
  Container,
  Image,
  ImageContainer,
  ButtonContainer
} from './styles';

import Button from '../../UI/Button';
import { linkResolver } from '../../../utils/linkResolver';

const WorkGrid = ({ work, limit, title, bgColor, buttonTitle, buttonLink }) => {
  // Filter
  let filterWork = [];
  if (work && limit) {
    work.forEach((item, index) => index < limit && filterWork.push(item));
  } else {
    filterWork = work;
  }
  
  // Content
  const renderContent = (work) => {
    if (work) {
      return work.map((item, i)=> {
        const { title, featured_image } = item.data;
        let image;
        if (featured_image) {
          image = (
            <ImageContainer>
              <Image
                fluid={featured_image.fluid} 
                alt={featured_image.alt} 
              />
            </ImageContainer>
          );
        }
        return (
          <Item
            key={`work-${i}`} 
            to={linkResolver(item)}
            data-sal='slide-up'
            data-sal-easing='ease' 
            data-sal-duration='600'
          >
            {image}
            <Title>
              {RichText.asText(title.raw)}
            </Title>
          </Item>
        );
      });
    } else {
      return (
        <p>
          No work found!
        </p>
      );
    }
  }

  // Section title
  const renderSectionTitle = (title) => {
    if (title) {
      return (
        <SectionTitle
          data-sal='slide-up'
          data-sal-easing='ease' 
          data-sal-duration='600'
        >
          {RichText.asText(title.raw)}
        </SectionTitle>
      );
    }
  }

  // Section button
  const renderSectionButton = (title, link) => {
    if (title && link) {
      return (
        <ButtonContainer>
          <Button 
            text={buttonTitle} 
            link={linkResolver(buttonLink)} 
          />
        </ButtonContainer>
      );
    }
  };

  return (
    <Section bgColor={bgColor}>
      <Container>
        {renderSectionTitle(title)}
        <Grid>
          {renderContent(filterWork)}
        </Grid>
        {renderSectionButton(buttonTitle, buttonLink)}
      </Container>
    </Section>
  );
};

export default WorkGrid;
